<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.accommodation_service_request') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <b-row>
              <b-col md="12">
                  <iq-card>
                      <template v-slot:headerTitle>
                          <h4 class="card-title">{{$t('portal.accommodation_service_request')}}</h4>
                      </template>
                      <template v-slot:body>
                        <b-overlay :show="loadData">
                          <b-row>
                            <b-col lg="12" sm="12">
                              <!-- <div>
                                <b-alert show dismissible variant="success" v-if="showMessage">
                                  {{$t('portal.sponsored_alert')}}
                                </b-alert>
                              </div> -->
                              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                                <b-form id="form" @submit.prevent="handleSubmit(createData)">
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                      <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('portal.applicant_information')}}</legend>
                                        <b-row class="p-3">
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Profession Type" vid="p_profession_type" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="p_profession_type"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                <template v-slot:label>
                                                  {{ $t('portal.applicant_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.p_profession_type"
                                                    :options="professionTypeList"
                                                    @input="traineeDetailsReset()"
                                                    id="p_profession_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                  <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12" v-if="formData.p_profession_type === 3">
                                            <ValidationProvider name="Person Profession Type" vid="pp_profession_type" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="pp_profession_type"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                <template v-slot:label>
                                                  {{ $t('elearning_accommodation.profession_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.pp_profession_type"
                                                    :options="personProfessionTypeList"
                                                    id="pp_profession_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                  <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="" v-else></b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Name (En)" vid="applicant_name" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="applicant_name"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.applicant_name')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="applicant_name"
                                                  v-model="formData.applicant_name"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="applicant_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.applicant_name')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="applicant_name_bn"
                                                v-model="formData.applicant_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Designation (En)" vid="applicant_designation"  rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="applicant_designation"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.applicant_designation')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="applicant_designation"
                                                  v-model="formData.applicant_designation"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Designation (Bn)" vid="applicant_designation_bn"  rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="applicant_designation_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.applicant_designation')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="applicant_designation_bn"
                                                v-model="formData.applicant_designation_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Mobile No" vid="mobile" rules="required|min:11|max:11">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="mobile"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    id="mobile"
                                                    v-model="formData.mobile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Email" vid="email" rules="required|email|min:3">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="email"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="email"
                                                v-model="formData.email"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12" v-if="formData.p_profession_type !== 3">
                                            <ValidationProvider name="Institution Name (En)" vid="institution_name" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="institution_name"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.name_institution')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="institution_name"
                                                  v-model="formData.institution_name"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12" v-if="formData.p_profession_type !== 3">
                                            <ValidationProvider name="Institution Name (Bn)" vid="institution_name_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="institution_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.name_institution')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="institution_name_bn"
                                                v-model="formData.institution_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Address of Individuals/Institution (En)" vid="address"  rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="address"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.address_individuals_institution')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="address"
                                                  v-model="formData.address"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Address of Individuals/Institution (Bn)" vid="address_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="address_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.address_individuals_institution')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="address_bn"
                                                v-model="formData.address_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                    </div>
                                  </b-row>
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                      <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('portal.room_use_info')}}</legend>
                                        <b-row class="p-3">
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Use Purpose" vid="use_purpose" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="use_purpose"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                <template v-slot:label>
                                                  {{ $t('portal.use_purpose')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.use_purpose"
                                                    :options="usePurposeList"
                                                    id="use_purpose"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                  <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Training Title (En)" vid="training_title" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="training_title"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('globalTrans.title')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="training_title"
                                                  v-model="formData.training_title"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Training Title (Bn)" vid="training_title_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="training_title_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('globalTrans.title')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="training_title_bn"
                                                v-model="formData.training_title_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Start Date" vid="start_date" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="start_date"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('elearning_accommodation.check_in')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  class="fromDate"
                                                  v-model="formData.start_date"
                                                  @change="validateInput(formData)"
                                                  :placeholder="$t('globalTrans.select_date')"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="End Date" vid="end_date" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="end_date"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('elearning_accommodation.check_out')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                class="fromDate"
                                                v-model="formData.end_date"
                                                @change="validateInput(formData)"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                    </div>
                                  </b-row>
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                      <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('portal.user_information')}}</legend>
                                          <div class="col-12" v-if="formData.p_profession_type === 1">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:18%">{{$t('portal.grade_from')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('portal.grade_to')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.male')}}</th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.female')}}</th>
                                                  <th style="width:20%">{{$t('elearning_accommodation.total_user')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_data, index) in formData.trainee_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'grade_from' + index" rules="required|min_value:1" name="Grade Form">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.grade_from"
                                                        :options="gradeList"
                                                        :id="'trainee_details['+index+'][grade_from]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'grade_to' + index" rules="required|min_value:1"  name="Grade to">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.grade_to"
                                                        :options="gradeList"
                                                        :id="'trainee_details['+index+'][grade_to]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'male_trainee' + index"  rules="required|min_value:1"  name="Male Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.male_trainee"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :id="'trainee_details['+index+'][male_trainee]'"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.male_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'female_trainee' + index" rules="" name="Female Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.female_trainee"
                                                        :id="'trainee_details['+index+'][female_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.female_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_trainee' + index" name="Total Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.total_trainee"
                                                        :id="'trainee_details['+index+'][total_trainee]'"
                                                        :placeholder="$t('portal.total_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment(index, trainee_data)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="6">
                                                  <button
                                                    @click="addMore"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                          <div class="col-12" v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 1">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:18%">{{$t('portal.grade_from')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('portal.grade_to')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.male')}}</th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.female')}}</th>
                                                  <th style="width:20%">{{$t('elearning_accommodation.total_user')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_data, index) in formData.trainee_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'grade_from' + index" rules="required|min_value:1" name="Grade Form">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.grade_from"
                                                        :options="gradeList"
                                                        :id="'trainee_details['+index+'][grade_from]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'grade_to' + index" rules="required|min_value:1"  name="Grade to">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.grade_to"
                                                        :options="gradeList"
                                                        :id="'trainee_details['+index+'][grade_to]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'male_trainee' + index"  rules="required|min_value:1"  name="Male Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.male_trainee"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :id="'trainee_details['+index+'][male_trainee]'"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.male_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'female_trainee' + index" rules="" name="Female Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.female_trainee"
                                                        :id="'trainee_details['+index+'][female_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.female_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_trainee' + index" name="Total Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.total_trainee"
                                                        :id="'trainee_details['+index+'][total_trainee]'"
                                                        :placeholder="$t('portal.total_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment(index, trainee_data)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="6">
                                                  <button
                                                    @click="addMore"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                          <div class="col-12" v-if="formData.p_profession_type === 2">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:18%">{{$t('portal.officer_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.male')}}</th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.female')}}</th>
                                                  <th style="width:20%">{{$t('elearning_accommodation.total_user')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_data, index) in formData.trainee_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'officer_type' + index" rules="required|min_value:1"  name="Officer Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.officer_type"
                                                        :options="officerTypeList"
                                                        :id="'trainee_details['+index+'][officer_type]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'male_trainee' + index"  rules="required|min_value:1"  name="Male Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.male_trainee"
                                                        :id="'trainee_details['+index+'][male_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.male_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'female_trainee' + index" rules="" name="Female Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.female_trainee"
                                                        :id="'trainee_details['+index+'][female_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.female_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_trainee' + index" name="Total Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.total_trainee"
                                                        :id="'trainee_details['+index+'][total_trainee]'"
                                                        :placeholder="$t('portal.total_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment(index, trainee_data)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="6">
                                                  <button
                                                    @click="addMore"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                          <div class="col-12" v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 2">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:18%">{{$t('portal.officer_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.male')}}</th>
                                                  <th style="width:18%">{{$t('elearning_accommodation.female')}}</th>
                                                  <th style="width:20%">{{$t('elearning_accommodation.total_user')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_data, index) in formData.trainee_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'officer_type' + index" rules="required|min_value:1"  name="Officer Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.officer_type"
                                                        :options="officerTypeList"
                                                        :id="'trainee_details['+index+'][officer_type]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'male_trainee' + index"  rules="required|min_value:1"  name="Male Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.male_trainee"
                                                        :id="'trainee_details['+index+'][male_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.male_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'female_trainee' + index" rules="" name="Female Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.female_trainee"
                                                        :id="'trainee_details['+index+'][female_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.female_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_trainee' + index" name="Total Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.total_trainee"
                                                        :id="'trainee_details['+index+'][total_trainee]'"
                                                        :placeholder="$t('portal.total_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment(index, trainee_data)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="6">
                                                  <button
                                                    @click="addMore"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                      </fieldset>
                                    </div>
                                  </b-row>
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                    <fieldset class="p-2 w-100">
                                      <legend class="px-2 w-50 shadow-sm">{{$t('portal.room_information')}}</legend>
                                      <b-row class="mx-3">
                                        <div class="col-12" v-if="formData.p_profession_type === 1">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:15%">{{$t('portal.grade_from')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('portal.grade_to')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('elearning_config.room_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('portal.number_of_room')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.room_rent')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.total_rent')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(detail, index) in formData.accommodation_room_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'grade_from' + index" rules="required|min_value:1" name="Grade Form">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.grade_from"
                                                        @change="filterRoomTypeList(detail)"
                                                        :options="gradeList"
                                                        :id="'accommodation_room_details['+index+'][grade_from]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'grade_to' + index" rules="required|min_value:1"  name="Grade to">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.grade_to"
                                                        @change="filterRoomTypeList(detail)"
                                                        :options="gradeList"
                                                        :id="'accommodation_room_details['+index+'][grade_to]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_type_id' + index" rules="required|min_value:1"  name="Room Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.room_type_id"
                                                        :options="detail.roomTypeList"
                                                        @change="filterRoomRentList(detail)"
                                                        :id="'accommodation_room_details['+index+'][room_type_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_number' + index"  rules="required|min_value:1"  name="Number of Room">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_number"
                                                        :id="'accommodation_room_details['+index+'][room_numner]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.number_of_room')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_rent' + index" rules="" name="Room Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_rent"
                                                        :id="'accommodation_room_details['+index+'][room_rent]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.room_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_rent' + index" name="Total Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.total_rent"
                                                        :id="'accommodation_room_details['+index+'][total_rent]'"
                                                        :placeholder="$t('elearning_config.total_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment2(index, detail)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7">
                                                  <button
                                                    @click="addMore2"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        <div class="col-12" v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 1">
                                          <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:15%">{{$t('portal.grade_from')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('portal.grade_to')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('elearning_config.room_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('portal.number_of_room')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.room_rent')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.total_rent')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(detail, index) in formData.accommodation_room_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'grade_from' + index" rules="required|min_value:1" name="Grade Form">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.grade_from"
                                                        @change="filterRoomTypeList(detail)"
                                                        :options="gradeList"
                                                        :id="'accommodation_room_details['+index+'][grade_from]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'grade_to' + index" rules="required|min_value:1"  name="Grade to">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.grade_to"
                                                        @change="filterRoomTypeList(detail)"
                                                        :options="gradeList"
                                                        :id="'accommodation_room_details['+index+'][grade_to]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_type_id' + index" rules="required|min_value:1"  name="Room Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.room_type_id"
                                                        :options="detail.roomTypeList"
                                                        @change="filterRoomRentList(detail)"
                                                        :id="'accommodation_room_details['+index+'][room_type_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_number' + index"  rules="required|min_value:1"  name="Number of Room">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_number"
                                                        :id="'accommodation_room_details['+index+'][room_numner]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.number_of_room')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_rent' + index" rules="" name="Room Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_rent"
                                                        :id="'accommodation_room_details['+index+'][room_rent]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.room_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_rent' + index" name="Total Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.total_rent"
                                                        :id="'accommodation_room_details['+index+'][total_rent]'"
                                                        :placeholder="$t('elearning_config.total_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment2(index, detail)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7">
                                                  <button
                                                    @click="addMore2"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        <div class="col-12" v-if="formData.p_profession_type === 2">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:18%">{{$t('portal.officer_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('elearning_config.room_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('portal.number_of_room')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.room_rent')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.total_rent')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(detail, index) in formData.accommodation_room_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'officer_type' + index" rules="required|min_value:1"  name="Officer Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.officer_type"
                                                        :options="officerTypeList"
                                                        @change="filterRoomTypeList(detail)"
                                                        :id="'detail['+index+'][officer_type]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_type_id' + index" rules="required|min_value:1"  name="Room Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.room_type_id"
                                                        :options="detail.roomTypeList"
                                                        @change="filterRoomRentList(detail)"
                                                        :id="'accommodation_room_details['+index+'][room_type_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_number' + index"  rules="required|min_value:1"  name="Room Number">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_number"
                                                        :id="'accommodation_room_details['+index+'][room_numner]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.room_numner')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_rent' + index" rules="" name="Room Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_rent"
                                                        :id="'accommodation_room_details['+index+'][room_rent]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.room_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_rent' + index" name="Total Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.total_rent"
                                                        :id="'accommodation_room_details['+index+'][total_rent]'"
                                                        :placeholder="$t('elearning_config.total_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment2(index, detail)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7">
                                                  <button
                                                    @click="addMore2"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        <div class="col-12" v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 2">
                                          <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:18%">{{$t('portal.officer_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('elearning_config.room_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:15%">{{$t('portal.number_of_room')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.room_rent')}}</th>
                                                  <th style="width:15%">{{$t('elearning_config.total_rent')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(detail, index) in formData.accommodation_room_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'officer_type' + index" rules="required|min_value:1"  name="Officer Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.officer_type"
                                                        :options="officerTypeList"
                                                        @change="filterRoomTypeList(detail)"
                                                        :id="'detail['+index+'][officer_type]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_type_id' + index" rules="required|min_value:1"  name="Room Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="detail.room_type_id"
                                                        :options="detail.roomTypeList"
                                                        @change="filterRoomRentList(detail)"
                                                        :id="'accommodation_room_details['+index+'][room_type_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_number' + index"  rules="required|min_value:1"  name="Room Number">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_number"
                                                        :id="'accommodation_room_details['+index+'][room_numner]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.room_numner')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'room_rent' + index" rules="" name="Room Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.room_rent"
                                                        :id="'accommodation_room_details['+index+'][room_rent]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalRent(detail)"
                                                        :placeholder="$t('portal.room_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_rent' + index" name="Total Rent">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="detail.total_rent"
                                                        :id="'accommodation_room_details['+index+'][total_rent]'"
                                                        :placeholder="$t('elearning_config.total_rent')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment2(index, detail)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7">
                                                  <button
                                                    @click="addMore2"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                      </b-row>
                                      <b-row class="mx-3">
                                        <b-col lg="6" sm="12" >
                                          <ValidationProvider name="Grand Total" vid="grand_total" rules="required|min_value:1">
                                            <b-form-group
                                              class="row"
                                              label-cols-sm="5"
                                              label-for="grand_total"
                                              slot-scope="{ valid, errors }"
                                            >
                                              <b-form-input
                                                hidden
                                                v-model="grandTotal"
                                                id="grand_total"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12" class=" text-right">
                                          <ValidationProvider name="Grand Total" vid="grand_total">
                                            <b-form-group
                                              class="row"
                                              label-cols-sm="8"
                                              label-for="grand_total"
                                              slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{ $t('portal.grand_total') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input
                                                readonly
                                                v-model="grandTotal"
                                                id="grand_total"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                      </b-row>
                                    </fieldset>
                                  </div>
                                </b-row>
                                <br/>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                              </ValidationObserver>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                  </iq-card>
              </b-col>
          </b-row>
          <!-- <pre>{{ formData.accommodation_room_details }}</pre> -->
      </b-container>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'
// import { EventBus } from '@/EventBusLayout'
// const today = new Date().toISOString().substr(0, 10)

export default {
    mixins: [ModalBaseMasterList],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
        showMessage: false,
        headerText: { text_en: '', text_bn: '' },
        loadData: false,
        grand_total: '',
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        formData: {
            id: '',
            p_profession_type: 0,
            pp_profession_type: 0,
            applicant_name: '',
            applicant_name_bn: '',
            mobile: '',
            email: '',
            institution_name: '',
            institution_name_bn: '',
            applicant_designation: '',
            applicant_designation_bn: '',
            address: '',
            address_bn: '',
            use_purpose: 0,
            training_title: '',
            training_title_bn: '',
            start_date: '',
            start_date_error: '',
            end_date_error: '',
            end_date: '',
            status: 1,
            accommodation_status: 1,
            trainee_details_remove: [],
            trainee_details: [
              {
                id: '',
                officer_type: 0,
                grade_from: 0,
                grade_to: 0,
                male_trainee: 0,
                female_trainee: 0,
                total_trainee: 0
              }
            ],
            org_id: 12,
            accommodation_room_details: [
              {
                grade_from: 0,
                grade_to: 0,
                // roomType: 0,
                officer_type: 0,
                room_type_id: 0,
                room_number: 0,
                room_rent: 0,
                vat_tax: 0,
                roomTypeList: [],
                total_rent: 0
              }
            ]
        },
        officeTypeList: [],
        officeList: [],
        guestHouseList: [],
        roomList: [],
        roomTypeList: [],
        floorList: []
      }
    },
    watch: {
      'formData.org_id': function (newVal, oldVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
      },
      'formData.office_type_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.officeList = this.getOfficeList(newVal)
          }
      }
    },
    computed: {
      grandTotal: function () {
        return this.formData.accommodation_room_details.reduce((acc, detail) => {
          const totalRent = parseFloat(detail.total_rent)
          return acc + (isNaN(totalRent) ? 0 : totalRent)
        }, 0)
      },
      professionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Organization', text_bn: 'সরকারি প্রতিষ্ঠান' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Non Government Organization' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Non Government Organization', text_bn: 'বেসরকারি প্রতিষ্ঠান' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
        ]
        return professionTypeList
      },
      personProfessionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government' : 'সরকারি', text_en: 'Government', text_bn: 'সরকারি' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Non Government' : 'বেসরকারি', text_en: 'Non Government', text_bn: 'বেসরকারি' }
        ]
        return professionTypeList
      },
      roomUserTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Own Organization' : 'নিজস্ব প্রতিষ্ঠান' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Govt' : 'সরকারী' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Private' : 'ব্যক্তিগত' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Domestic and Foreign Consultants' : 'দেশী-বিদেশী পরামর্শক' }
        ]
      },
      usePurposeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Seminer' : 'সেমিনার' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Meeting' : 'মিটিং' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Personal' : 'ব্যক্তিগত' }
        ]
      },
      officerTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা' }
        ]
      },
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      gradeList: function () {
        return this.$store.state.Portal.commonObj.gradeList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
      },
      fiscalYearList: function () {
        return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
      }
    },
    async created () {
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getBranchList (Id) {
          this.branchList = this.$store.state.CommonService.commonObj.branchList.filter(item => item.bank_id === parseInt(Id))
      },
      getGrandTotal: function (values) {
        return values.reduce((acc, val) => {
          return acc + parseInt(val.detail)
        }, 0)
      },
      remove (key) {
        this.formData.accommodation_room_details.splice(key, 1)
      },
      getOfficeTypeList (orgId = null) {
          const officeTypeList = this.$store.state.Portal.commonObj.officeTypeList.filter(item => item.status === 0)
          if (orgId) {
              return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
          }
          return officeTypeList
      },
      getOfficeList (officeTypeId = null) {
          const officeList = this.$store.state.Portal.commonObj.officeList.filter(item => item.status === 0)
          if (officeTypeId) {
              return officeList.filter(office => office.office_type_id === officeTypeId)
          }
          return officeList
      },
      getRoomTypeList (houseId = null) {
          const houseList = this.$store.state.Portal.trainObj.roomTypeSetupList.filter(item => item.status === 1)
          if (houseId) {
              return houseList.filter(house => house.guest_house_id === houseId).map(obj => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
          }
          return houseList
      },
      traineeDetailsReset () {
        this.formData.applicant_name = ''
        this.formData.applicant_name_bn = ''
        this.formData.mobile = ''
        this.formData.email = ''
        this.formData.institution_name = ''
        this.formData.institution_name_bn = ''
        this.formData.applicant_designation = ''
        this.formData.applicant_designation_bn = ''
        this.formData.address = ''
        this.formData.address_bn = ''
        this.formData.use_purpose = 0
        this.formData.training_title = ''
        this.formData.training_title_bn = ''
        this.formData.start_date = ''
        this.formData.end_date = ''
        this.formData.trainee_details = [{
          id: '',
          officer_type: 0,
          grade_from: 0,
          grade_to: 0,
          male_trainee: 0,
          female_trainee: 0,
          total_trainee: 0
        }]
        this.formData.accommodation_room_details = [{
          id: '',
          officer_type: 0,
          grade_from: 0,
          grade_to: 0,
          room_type_id: 0,
          room_number: 0,
          room_rent: 0,
          vat_tax: 0,
          total_rent: 0
        }]
        // this.formData.trainee_details = []
        this.$refs.form.reset()
      },
      addMore () {
        this.formData.trainee_details.push({
          id: '',
          officer_type: 0,
          grade_from: 0,
          grade_to: 0,
          male_trainee: 0,
          female_trainee: 0,
          total_trainee: 0
        })
      },
      addMore2 () {
        this.formData.accommodation_room_details.push({
          id: '',
          room_type_id: 0,
          grade_from: 0,
          grade_to: 0,
          room_number: 0,
          room_rent: 0,
          total_rent: 0
        })
      },
      removeAttachment (index, data) {
        this.formData.trainee_details.splice(index, 1)
      },
      removeAttachment2 (index, data) {
        this.formData.accommodation_room_details.splice(index, 1)
      },
      validateInput () {
        const start = new Date(this.formData.start_date)
        const end = new Date(this.formData.end_date)
        this.formData.start_date_error = ''
        this.formData.end_date_error = ''
        if (start > end) {
          this.$refs.form.setErrors('Start date must be before end date')
        }
      },
      totalTrainee (data) {
        let totalTrainee = 0
        totalTrainee = parseInt(data.male_trainee) + parseInt(data.female_trainee)
        data.total_trainee = totalTrainee
        return data
      },
      filterRoomTypeList (detail) {
        if (detail.grade_from && detail.grade_to) {
          const data = this.$store.state.Portal.trainObj.gradeSetupList.filter(item => item.grade_from <= parseInt(detail.grade_from) && item.grade_to >= parseInt(detail.grade_to))
          const type = []
          data.map(item => {
            const roomTypeObj = this.$store.state.Portal.trainObj.roomTypeSetupList.find(doc => doc.value === parseInt(item.room_type))
            type.push(roomTypeObj)
          })
          detail.roomTypeList = type
        } else if (detail.officer_type) {
          const data = this.$store.state.Portal.trainObj.gradeSetupList.filter(item => item.officer_type_id === parseInt(detail.officer_type))
          const type = []
          data.map(item => {
            const roomTypeObj = this.$store.state.Portal.trainObj.roomTypeSetupList.find(doc => doc.value === parseInt(item.room_type))
            type.push(roomTypeObj)
          })
          detail.roomTypeList = type
        } else {
          detail.roomTypeList = []
        }
        return detail
      },
      // filterRoomTypeList (detail) {
      //   if (detail.grade_from && detail.grade_to) {
      //     const data = this.$store.state.Portal.trainObj.gradeSetupList.filter(item => item.grade_from <= parseInt(detail.grade_from) && item.grade_to >= parseInt(detail.grade_to))
      //     const type = []
      //     data.map(item => {
      //       const roomTypeObj = this.$store.state.Portal.trainObj.roomTypeSetupList.find(doc => doc.value === parseInt(item.room_type))
      //       type.push(roomTypeObj)
      //     })
      //     this.roomTypeList = type
      //   } else if (detail.officer_type) {
      //     const data = this.$store.state.Portal.trainObj.gradeSetupList.filter(item => item.officer_type_id === parseInt(detail.officer_type))
      //     const type = []
      //     data.map(item => {
      //       const roomTypeObj = this.$store.state.Portal.trainObj.roomTypeSetupList.find(doc => doc.value === parseInt(item.room_type))
      //       type.push(roomTypeObj)
      //     })
      //     this.roomTypeList = type
      //   } else {
      //     this.roomTypeList = []
      //   }
      // },
      filterRoomRentList (detail) {
        if (detail.room_type_id && detail.grade_from && detail.grade_to) {
          const data = this.$store.state.Portal.trainObj.roomRentSetupList.find(item => item.room_type_id === parseInt(detail.room_type_id) && item.org_type_id === 1)
          detail.room_rent = data.rent
        } else if (detail.room_type_id && detail.officer_type) {
          const data = this.$store.state.Portal.trainObj.roomRentSetupList.find(item => item.room_type_id === parseInt(detail.room_type_id) && item.org_type_id === 2)
          detail.room_rent = data.rent
        } else {
          detail.room_rent = 0
        }
      },
      totalRent (data) {
        let totalRent = 0
        const startDate = new Date(this.formData.start_date)
        const endDate = new Date(this.formData.end_date)
        const dateDifferenceMs = endDate.getTime() - startDate.getTime()
        const noOfDays = Math.floor(dateDifferenceMs / (1000 * 60 * 60 * 24))
        totalRent = parseInt(data.room_number) * parseInt(data.room_rent) * noOfDays
        data.total_rent = totalRent
        return data
      },
      async createData () {
        this.loadData = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        result = await RestApi.postData(trainingElearningServiceBaseUrl, 'portal/accommodation-service-request/store', this.formData)

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push({
            path: '/training/accommodation-service-details',
            query: { id: result.data.id }
          })
          this.formData = {}
          this.$refs.form.reset()
          this.showMessage = true
        } else {
          this.$refs.form.setErrors(result.errors)
        }
        this.loadData = false
      }
    }
}
</script>
<style>
  .alert .close {
    color: red !important;
  }
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 16px!important;
    padding: 6px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
</style>
